// home
AliasBoot.ready(function () {

    // SLIDESHOW of videos
    /*
    var vidSlideshow = new VideoSlideshow($("#SplashVideoContainer > video"));

   

 
    function onVidSlideshowProgress(progress) {
       
        TweenMax.set(progressBar, { scaleX:(1-progress) } );
    }
    vidSlideshow.signalOnProgress.add(onVidSlideshowProgress);
    */

 var progressBar = $("#SplashVideoContainer > .progressbar");
   var splashVideo =  $("#SplashVideoContainer > video")[0];
   
   function onEnterFrame() {
          var v = splashVideo;


       var progress = (v.currentTime %4)   / 4;
     //  console.log(progress);
         TweenMax.set(progressBar, { scaleX:(1-progress) } );
    
       window.requestAnimationFrame(onEnterFrame);
   }
    window.requestAnimationFrame(onEnterFrame);

  _activeId =  window.requestAnimationFrame( onEnterFrame);
    /*  // not working atm..
    function onBreakChange(mq) {
        if (mq.matches) {
              vidSlideshow.resumeSlideshow();
        }
        else {
              vidSlideshow.stopSlideshow();
        }
    }
    App.BREAKPOINTS[App.BREAK_MD].addListener(onBreakChange);
    onBreakChange(App.BREAKPOINTS[App.BREAK_MD]);
    */


    // TABS

    var SSPages = new SectionTabLinker($("#TabServicesVsSolutions"), SetupFunctionClicks( $("#TabServicesVsSolutions a")), $("section.viewsection"), false);
    var ssPage1 = new SectionTabLinker($("#TabServicesIcons"),SetupFunctionClicks( $("#TabServicesIcons a")), $("#TabServicesContent > div"), false);
   
    var ssPage2 = new SectionTabLinker($("#TabSolutionIcons"),SetupFunctionClicks( $("#TabSolutionIcons a")), $("#TabSolutionsContent > div"), false);

    var dockerTabsQ  = $("#TabServicesVsSolutions");
   var dockerTabs =  new DockerTabs(dockerTabsQ);


    var breakpointStackIcons = App.BREAKPOINTS[App.BREAK_MD];
    var scrollTarget = $("html body");
    var windowTarget = $(window);
    var refViewSection = $(".viewsection-container .product-icon-right");
    /*
     $("#TabServicesIcons a").add("#TabSolutionIcons a").click( function() {
         if (!breakpointStackIcons.matches) {
             TweenMax.to(scrollTarget, .7, { scrollTop: refViewSection.offset().top-65 } )  // $(dockerTabs.passFixed.getIsPassed() ? "#TabServicesVsSolutions_Imposter" : "TabServicesVsSolutions").offset().top
         }
     });
     */
     //
    $("#TabServicesVsSolutions a").click( function() {
        if (dockerTabs.passFixed.getIsPassed()) {
            
        }
    });
    

    var sectionContainerQ = $("#ViewSectionContainer");
    var sectionContainer = sectionContainerQ[0];
    var navBarLogo = $("#LogoNav");
    var flagState = 0;
    windowTarget.scroll( function() {
        var boo = sectionContainer.getBoundingClientRect().bottom < 40;
       // console.log('ll:'+sectionContainer.getBoundingClientRect().bottom);
       if (flagState !== boo ) {
           flagState = boo;
            if ( flagState) {
               //dockerTabsQ.css("visibility", "hidden");
               dockerTabsQ.addClass("hideup");
               navBarLogo.addClass("overmap");
            }
            else {
               // dockerTabsQ.css("visibility", "visible");
                 dockerTabsQ.removeClass("hideup");
                 navBarLogo.removeClass("overmap");
            }
       }
      
    });

    // Digits implementation
    (function() {
       
     
        var CustomEase = com.greensock._class("easing.CustomEase", function(steps, falloff, falloffEase) {
              steps = steps || 1;
				this._p1 = 1 / steps;
            
				this._p2 = steps + 1;
                this._falloffPt = 1-falloff;
                this._falloff = falloff;
                this._fallRatioGetter = falloffEase.getRatio;
            
        }, true);
        var p = CustomEase.prototype = new Ease();	
        p.constructor = CustomEase;
       p.getRatio = function(p) {
        
           
			if (p < 0) {
				p = 0;
			} else if (p >= 1) {
				p =0.999999999;
			}


			return ((this._p2 * p) >> 0) * this._p1;
           
		};
        p.config = CustomEase.config = function(steps, falloff, falloffEase) {
  
            return new CustomEase(steps, falloff, falloffEase);
        };

        //        
       

        //
       
        var SpecialFalloffEase = CustomEase.config(10, .4, Linear.easeNone);



        var jq = $("#DigitsContainer");
        var digitsContainer = new DigitsContainer( jq, SteppedEase.config(10) ); // SteppedEase.config(10) 
        var t;


       // var myTimeline = new TimelineMax();

/*
        function getTimelineFromTo(from, to) {
            var dg = digitsContainer.getTimelineFromTo(from, to);    
            return dg;
        }
        //
     
        var initTime = 0;

         myTimeline.add([       
            TweenMax.to( getTimelineFromTo(initTime, (t=20)), 12, { ease:Linear.easeNone, progress:1, delay:0.25 } )
        
         ], 0, "sequence");
       */

         var sr = App.scrollReveal;

          // digitsContainer.setInitialTime(initTime);
              //   myTimeline.pause();



                 digitsContainer.setInitialTime(0);
              var myTimeline = new TweenMax(digitsContainer, 3, { delay:1, setInitialTime: 100000, ease:Linear.easeNone} );
           
              


          
          sr.reveal( jq.get(0), {
                      
                        distance    : 0, // Can be any valid CSS distance, e.g. '5rem', '10%', '20vw', etc.
                        duration    :0, // Time in milliseconds.
                        delay       : 0,
                      
                        easing      : 'linear', // Accepts any valid CSS easing, e.g. 'ease', 'ease-in-out', 'linear', etc.
                        container   : 'html',
                        mobile      : true, // true/false to control reveal animations on mobile.
                        reset       : 'always',
                        // 'always' — delay for all reveal animations
                        // 'once'   — delay only the first time reveals occur
                        // 'onload' - delay only for animations triggered by first load
                        useDelay    : 'always',
                        viewFactor  : 0.25, // 0.20 means 20% of an element must be visible for its reveal to occur.
                        viewOffset  : { top: 0, right: 0, bottom: 0, left: 0 }, // Visual Aid: https://scrollrevealjs.org/assets/viewoffset.png
                       
                        afterReveal : function(domEl) { myTimeline.resume(0); },
                        afterReset  : function(domEl) { myTimeline.pause(); myTimeline.time(0); }
                    });
          
      
      
            
      //   myTimeline.resume(0);

         
  

     })(); 


});